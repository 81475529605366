import React, { useState, useRef, useEffect } from 'react';
import { motion, useDragControls } from "framer-motion";
import { FaFolder, FaDownload, FaUser, FaGithub, FaLinkedin, FaRegFilePdf, FaWindowClose, FaWindowRestore, FaReact, FaLaravel, FaJs, FaCpanel, FaHtml5, FaCss3Alt, FaBootstrap, FaGitAlt, FaFilePdf, FaDotCircle } from 'react-icons/fa';
import Data from "./data/website.json";
import './App.css';

function App() {
// Set Time
const [clockState, setClockState] = useState();

useEffect(()=>{
  setInterval(() => {
    const date = new Date();
    setClockState(date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }));
  }, 1000);

}, []);
// function for Body 
  function hidingItems() {
    let social = document.getElementById('social');
    if (social.style.display === "block") {
      social.style.display = "none"
    }
  }
// function for download
  function loadOnClick() {
    let toggleLoad = document.getElementById('download');;
    let back = document.getElementById('backFilter');

    if (toggleLoad.style.display === "none") {
      toggleLoad.style.display = "block";
      back.style.filter = "blur(10px)";
    } else {
      toggleLoad.style.display = "none";
      back.style.filter = "blur(0px)";
    }
  }
// function for démarrer 
  function skillsOnClick() {
    let toggleSkills = document.getElementById('skills');
    let back = document.getElementById('backFilter');

    if (toggleSkills.style.display === "none") {
      toggleSkills.style.display = "block";
      back.style.filter = "blur(10px)";
    } else {
      toggleSkills.style.display = "none";
      back.style.filter = "blur(0px)";
    }
  }
// function for the profil section
  function profilOnClick() {
    let toggleProfil = document.getElementById('profil');
    let activeBack = document.getElementById('user');
    let back = document.getElementById('backFilter');

  if (toggleProfil.style.display === "none") {
    toggleProfil.style.display = "block";
    toggleProfil.style.transition = "1s";
    activeBack.style.backgroundColor = "#7E7E7E";
    activeBack.style.borderBottom = "5px solid white";
    activeBack.style.padding = "0 15px 0 15px";
    back.style.filter = "blur(10px)";
  } else {
    toggleProfil.style.display = "none";
    toggleProfil.style.transition = "1s";
    activeBack.style.backgroundColor = "transparent";
    activeBack.style.borderBottom = "5px solid #357EC7";
    activeBack.style.padding = "0 15px 0 15px";
    back.style.filter = "blur(0px)";
  }
  }

  function closeOnClick() {
    document.getElementById('profil').style.display = 'none';
    document.getElementById('profil').style.transition = '1s';
    document.getElementById('user').style.backgroundColor = "transparent";
    document.getElementById('user').style.borderBottom = "5px solid #357EC7";
    document.getElementById('user').style.padding = "0 15px 0 15px";
    document.getElementById('backFilter').style.filter = "blur(0px)";
  }

  function restoreOnClick() {
    let restore = document.getElementById('profil');

    if (restore.style.width === '250px') {
      restore.style.width = '300px';
    } else {
      restore.style.width = '250px';
    }
  }
  // profil hover
  function onHover() {
    let socialHover = document.getElementById('social');

    if (socialHover.style.display === "none") {
      socialHover.style.display = "block"
    } else {
      socialHover.style.display = "none"
    }
  }
  // END function for the profil section
  // dark and light 
  function light() {
    document.getElementById('navigation-bar').style.background = "#C4C4C4";
    document.getElementById('skills').style.background = "#C4C4C4";
    document.getElementById('profil').style.background = "#C4C4C4";
    document.getElementById('time-clock').style.color = "black";

  }
  function dark() {
    document.getElementById('navigation-bar').style.background = "rgb(50, 50, 50)";
    document.getElementById('skills').style.background = "rgb(50, 50, 50)";
    document.getElementById('profil').style.background = "rgb(50, 50, 50)";
    document.getElementById('time-clock').style.color = "#EDEADE";
  }
  // animations
  const [move, setMove] = React.useState(false);

  useEffect(()=> {
    setMove(true);
  }, []);

  // drag control
  const controls = useDragControls()

  function startDrag(event) {
    controls.start(event)
  }
 
  return (
    <>
    <div id='dashboard-background' onClick={hidingItems}>
      <div className='dashboard' id='backFilter'>
        {/* icon website button */}
        <div className='link-website'>

        {
          Data.map((dataWeb, index)=>{
            return(
              <>
              <div onPointerDown={startDrag} />
              <motion.div drag whileDrag={{scale: 1.2}} dragConstraints={{left: 2}} dragControls={controls} className='each-link'>
                
                <div className='icons'>
                <a href={dataWeb.link} target="_blank">
                  <div> 
                    <FaFolder />
                  </div>
                </a>
                </div>
                <div className='website'>
                  <span>
                    {dataWeb.title}
                  </span>
                </div>
                <div className='image-div'>
                <a href={dataWeb.link} target="_blank">
                  <img src={dataWeb.img} className="img-web" />
                </a>
                  
                </div>
              </motion.div>
              
              </>
            )
          })
        }

        </div>
        {/* icon website button */}
      <div>
      </div>
      </div>
      {/* div profile social media */}
      <div className='div-profil' id='profil'> 
        <div className='close'>
          <button onClick={restoreOnClick} className='restoreButton'>
            <FaWindowRestore />
          </button>
          <button onClick={closeOnClick} className='closeButton'>
            <FaWindowClose />
          </button>
        </div>
      
        <div className='window-profil'>
          <ul>
            <li className='job'>
              <h1>Margaux K. Bofane</h1>
            </li>
            <li className='job'>
              Front End Junior <span className='blue'>Developer</span>
            </li>
            <li className='list'>
              <a href='https://www.linkedin.com/in/margaux-kabwanga-bofane/' target='_blank'>
                <span className='icon-profil'> <FaLinkedin /> </span>  
              Linkedin</a>
            </li>
            <li className='list'>
              <a href='https://github.com/mukaji-k' target='_blank'>
                <span className='icon-profil'> <FaGithub /> </span>
              Github</a>
            </li>
          </ul>
        </div>
        
      </div>
      {/* END div profile social media */}
      {/* div demarrer */}
      <div className='div-skills' id='skills'>
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.3, duration:1, repeatType: "reverse", repeat: Infinity, ease: "linear"}} move={move} > 
              <FaCss3Alt className='skills'/> </motion.div> 
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.2, duration:1, repeatType: "reverse",  repeat: Infinity, ease: "easeInOut"}}  move={move} > 
              <FaHtml5 className='skills' /> </motion.div>
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.3, duration:1, repeatType: "reverse",  repeat: Infinity, ease: "easeIn"}}  move={move} > 
              <FaBootstrap className='skills' /> </motion.div>
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.2, duration:1, repeatType: "reverse",  repeat: Infinity, ease: "easeOut"}}  move={move} > 
              <FaReact className='skills' /> </motion.div>
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.3, duration:1, repeatType: "reverse",  repeat: Infinity, ease: "linear"}}  move={move} > 
              <FaLaravel className='skills' /> </motion.div>
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.2, duration:1, repeatType: "reverse",  repeat: Infinity, ease: "easeInOut"}}  move={move} > 
              <FaJs className='skills' /> </motion.div>
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.3, duration:1, repeatType: "reverse",  repeat: Infinity, ease: "easeIn"}}  move={move} > 
              <FaCpanel className='skills' /> </motion.div>
            <motion.div animate={{ x: move ? 110: 0}} transition={{delay: 0.2, duration:1, repeatType: "reverse",  repeat: Infinity, ease: "easeOut"}}  move={move} > 
              <FaGitAlt className='skills' /> </motion.div>
      </div>
      {/* END div demarrer */}
      {/* div download */}
      <div className='div-download' id='download'>
        <div className='francais'><span className='icon-profil'><FaFilePdf /></span> voir cv</div>
        <div className='anglais'><span className='icon-profil'><FaRegFilePdf /></span> see cv</div>
      </div>
      {/* END div download */}
      {/* footer */}
      <div id='navigation-bar' onClick={hidingItems}>
        <div className='left'>
          {/* start demarrer SKILLS SECTION */}
          
          <div className='div-button'>
            <button className='skills-button' onClick={skillsOnClick}>
              Skills
            </button>
          </div>
          {/* end demarrer SKILLS SECTION */}
          <div className='div-user' id='user' onClick={profilOnClick}>
            {/* logo profil */}
              <FaUser onMouseOver={onHover} />
          </div>
          <div id='social'>social media</div>
        </div>

        <div className='minicone'>
          <div className='colorDotOne'>
            <FaDotCircle onClick={light} />
          </div>
          <div className='colorDotTwo'>
            <FaDotCircle onClick={dark} />
          </div>
          <div className='time'>
            <div id='time-clock'>{clockState}</div>
          </div>
          <div className='download' onClick={loadOnClick}>
            <FaDownload />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default App;
